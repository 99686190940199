.ant-switch.st-switch {
  min-width: 37px;
  height: 20px;
  line-height: 20px;

  .ant-switch-handle {
    width: 16px;
    height: 16px;
  }

  &.ant-switch-checked {
    background-color: rgba(255, 184, 0, 0.8);

    .ant-switch-handle {
      left: calc(100% - 16px - 2px);
    }
  }
}
