.slider {
	margin-bottom: 32px;

	.swiper {
		border-radius: 3px;
	}

	.swiper-button-prev,
	.swiper-button-next {
		height: 50px;
		width: 50px;
		padding: 0;
		box-sizing: border-box;
		top: calc(50% - 25px);
		margin: 0;

		&:after {
			display: none;
		}
	}
	.swiper-button-next {
		background: url("./btn_next.svg") 50% 50% / 100% auto no-repeat;
		right: 41px;
	}
	.swiper-button-prev {
		background: url("./btn_prev.svg") 50% 50% / 100% auto no-repeat;
		left: 41px;
	}
	&_top {
		// max-width: 1100px;
		margin: 0 -16px 32px;
		.swiper {
			overflow: visible;
		}
		.swiper-slide {
			height: 300px;
			border-radius: 6px;
			padding: 0 16px;

			.slide {
				width: 100%;
				height: 100%;

				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
					display: block;
					border-radius: 6px;
				}
			}
		}
	}

	&_bottom {
		margin-bottom: 0;
		.swiper {
		}

		.swiper-slide {
			height: 299px;
		}
	}
}
