.mobile-body {
	.cart-position {
		position: relative;

		&__image {
			height: 88px;
		}

		&__actions {
			display: flex;
			justify-content: flex-end;

			button {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 32px;
				height: 32px;
			}
		}

		&__unavailable-price {
			text-align: left;
		}

		&__offer-name {
			font-size: 14px;
			line-height: 20px;
		}

		&__brand-name {
			font-size: 14px;
			line-height: 20px;
		}

		&__price {
			font-weight: 600;
			font-size: 16px;
			line-height: 22px;
			justify-content: flex-start;
			align-items: flex-start;
			flex-direction: column;

			.old-price {
				font-size: 12px;
				line-height: 14px;
			}
			.price-units {
				font-weight: 400;
				font-size: 12px;
				line-height: 14px;
			}
		}

		&__col {
			&_checkbox {
				position: absolute;
				right: 0;
				top: 16px;
			}

			&_img {
				width: 88px;
			}

			&_right {
				flex-direction: column;
				gap: 8px;
			}

			&_description {
				padding-right: 32px;
			}

			&_qty {
				width: 174px;
			}

			&_last {
				display: flex;
				gap: 16px;
				align-items: center;
				flex-direction: row-reverse;
				justify-content: flex-start;
				width: 100%;
				.qty-change__wrapper .qty-change__max {
					display: none;
				}
			}
		}

		&_unavailable {
			.cart-position {
				&__col {

					&_last {
						justify-content: flex-end;
					}
				}
			}
		}
	}
}
