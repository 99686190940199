.product-page {
	h1 {
		margin-bottom: 15px;
	}
}

.product-show-page__wrapper {
	display: flex;
	flex-direction: column;
	gap: 48px;
}

.product-page-part {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;

	h2 {
		font-weight: 700;
		font-size: 28px;
		line-height: 30px;
		letter-spacing: 0.01em;
		margin: 0;
		padding: 0;
		color: #000000;
	}

	&__top-sidebar {
		display: flex;
		gap: 24px;

		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
	}

	&__top {
		flex-direction: row;
		gap: 32px;
	}

	&__col {
		&_1 {
			flex: 1;
		}

		&_2 {
			width: 420px;
		}
	}

	.html-content {
		max-width: 800px;
		font-weight: 400;
		font-size: 16px;
		line-height: 28px;
		color: #000000;

		p:last-child {
			margin-bottom: 0;
		}
	}
}

.order-widget__wrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
	min-height: 100%;

	& > div {
		padding: 16px;

		background: rgba(162, 162, 162, 0.1);
		border-radius: 12px;
	}

	&-top {
		flex: 1;
	}

	&-bottom {
		height: 72px;
		display: flex;
		align-items: center;
	}
}
