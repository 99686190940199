.blocks-with-icons {
	margin-bottom: 64px;

	&-list {
		list-style: none;
		display: flex;
		justify-content: center;
		align-items: stretch;
		padding: 0;
		gap: 8px;
	}

	&-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		width: 115px;

		.icon- {
			height: 70px;
			width: 80px;
			display: flex;
			justify-content: center;
			margin-bottom: 12px;

			img {
				display: block;
				max-width: 100%;
				max-height: 100%;
			}
		}
		.content- {
			.link- {
				position: absolute; left: 0; top: 0; height: 100%; width: 100%;
			}
			.title- {
				font-weight: 600;
				font-size: 14px;
				line-height: 20px;
				text-align: center;
				color: #343434;
			}
		}
	}
}
