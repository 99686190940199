.mobile-body {
	.articles {
		flex-direction: column;

		&-list {
			gap: 16px;

			&-item {
				width: 100%;
				min-height: 200px;
				display: flex;

				&__description {
					font-weight: 600;
					font-size: 16px;
					line-height: 22px;
				}

				&__subtitle {
					font-size: 14px;
					line-height: 20px;
				}
			}
		}
	}
}
