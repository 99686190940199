.favourite-button {
	display: flex;
	align-items: center;
	gap: 4px;

	&__title {
		color: #1536A1;
		cursor: pointer;
	}
}
