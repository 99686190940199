.header {
	padding: 11px 0 17px;
	color: #fff;
	position: relative;
	z-index: 11;
	background: linear-gradient(90deg, #1336A7 -1.28%, #173082 72.04%, #181368 100%);

	.container {
		display: flex;
		flex-direction: column;
	}

	a {
		color: #fff;
	}

	&__top {
		width: 100%;
		display: flex;
		margin-bottom: 24px;
		justify-content: space-between;

		&-left-col {
			display: flex;
			gap: 16px;
			align-items: center;
		}

		&-right-col {
			display: flex;
			gap: 16px;
			align-items: center;

			a {
				color: #fff;
				font-weight: 400;
				font-size: 14px;
				line-height: 20px;
				opacity: .5;
			}
		}
	}

	&__logo {
		img {
			width: 232px;
		}
	}

	&__bottom {
		display: grid;
		grid-template-columns: auto 1fr auto;
		gap: 30px;
	}
}
