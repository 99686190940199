.react-tel-input.st-tel-input {
  .form-control {
    box-sizing: border-box;
    padding: 4px 12px;
    gap: 8px;
    height: 40px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    &,
    &:focus {
      outline: none;
      box-shadow: none;
      background: #FFFFFF;
      border: 1px solid rgba(162, 162, 162, 0.4);
      border-radius: 4px;
    }
  }
  .flag-dropdown {
    display: none;
  }
}

.mobile-body {
  .react-tel-input.st-tel-input {
    .form-control {
      border: 1px solid rgba(162, 162, 162, 0.4);
      width: 100%;
      border-radius: 4px;
    }
  }
}
