.mobile-body {
	.reviews-button {
		gap: 10px;

		&__rating {
			font-size: 14px;
			line-height: 20px;
		}
	}
}
