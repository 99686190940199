.rubric-content {
	.rubric-content__header {
		margin-bottom: 20px;

		h2 {
			font-weight: 700;
			font-size: 20px;
			line-height: 26px;
			color: #343434;
		}
	}
}
