.product-order-widget {
	display: flex;
	flex-direction: column;
	gap: 16px;

	&__row {
		display: flex;
		gap: 16px;
		flex-direction: column;
	}
}

.large-price-box {
	display: flex;
	flex-direction: column;
	gap: 8px;
	min-height: 104px;

	&__price-value {
		display: flex;
		gap: 4px;
		font-weight: 700;
		font-size: 32px;
		line-height: 42px;
		letter-spacing: 0.01em;
		color: #000000;
	}

	&__old-price {
		display: flex;
		gap: 8px;
		align-items: center;
		font-weight: 400;
		font-size: 12px;
		line-height: 145%;
		color: #9D9DA5;
	}
}

.units-select {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	gap: 4px;

	&__item {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 8px;
		gap: 4px;
		height: 32px;
		background: rgba(0, 0, 0, 0.05);
		border-radius: 4px;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: rgba(0, 0, 0, 0.8);
		box-sizing: border-box;
		cursor: pointer;
		border: 1.5px solid transparent;
		transition: all .2s linear;

		&_active {
			border: 1.5px solid rgba(255, 184, 0, 0.8);
			border-radius: 4px;
			background: transparent;
			cursor: default;
		}
	}
}
