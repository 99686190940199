.st-check.ant-checkbox-wrapper {
  align-items: flex-start;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;

  .ant-checkbox + span {
    padding-right: 6px;
    padding-left: 6px;
  }

  & + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  .ant-checkbox {
    top: 0;
    width: 20px;
    height: 20px;

    &:hover .ant-checkbox-inner {
      border: 0;
    }
  }

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border: 0;
    background: rgba(162, 162, 162, 0.3);
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.05);

    border-radius: 4px;

    &:after {
      height: 10.5px;
      width: 6.5px;
      top: 43%;
      left: 30%;
    }
  }

  .ant-checkbox-checked {
    &::after {
      border: 0;
    }

    .ant-checkbox-inner {
      background: rgba(255, 184, 0, 0.8);
      &::after {
        border: 2px solid #000;
        border-top: 0;
        border-left: 0;
      }
    }
  }

  .ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      &::after {
        background-color: var(--gray500);
      }
    }
  }
}
