.mobile-body {
  .container {
    max-width: 100%;
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
  }

  .page-layout {
    &__header {
      padding-bottom: 0;
    }

    &__content {
      flex: 1;
      padding: 16px 0;
      overflow-x: hidden;
      min-height: 80vh;

      h1 {
        display: flex;
        align-items: flex-end;
        width: 100%;
        font-weight: 700;
        font-size: 26px;
        line-height: 30px;
        margin-bottom: 40px;
        color: #000000;

        .counters {
          white-space: nowrap;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.4);
        }
      }
    }
  }

  .page-content-section {
    h2,
    h3 {
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      color: #343434;
      margin-bottom: 26px;
      display: flex;
      align-items: center;
    }

    .all-records-link {
      &,
      &:active,
      &:hover {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #1536A1;
      }
    }
  }
  .page-content-section {
    margin-bottom: 43px;
  }

  .two-cols-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 43px;

    &__col {
      &_left {
        width: 100%;
      }

      &_right {
        flex: 1;
        padding-left: 0;
      }
    }
  }

  .ant-drawer {

    &.ant-drawer-bottom-radius {
      .ant-drawer-content {
        border-radius: 6px 6px 0 0;
      }
    }

    &.ant-drawer-top-radius {
      .ant-drawer-content {
        border-radius: 0 0 6px 6px;
      }
    }

    &.text-drawer {
      .ant-drawer-content {
        border-radius: 6px 6px 0 0;
      }
      .ant-drawer {
        &-body {
          max-height: 60vh;
        }
      }
    }
    .ant-drawer-header {
      border: 0;
      padding: 16px;

      .ant-drawer-header-title {
        position: relative;

        .ant-drawer-close {
          position: absolute;
          right: 2px;
          top: 4px;
          margin: 0;
        }
      }
    }

    .ant-drawer-body {
      padding: 4px 16px;
      overflow-y: scroll;
    }

    .burger-menu-options {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;

      .burger-menu-option {
        display: flex;
        justify-content: space-between;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;

        &__active {
          font-weight: 600;
        }
      }
    }

    .ant-drawer-footer {
      border: 0;
      padding: 8px 16px 16px 16px;
    }
  }

  .html-content {
    font-size: 14px;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.1px;
    color: #000;

    strong, b { font-weight: 500; }
    p { margin-bottom: 6px; }

    iframe,
    img {
      max-width: 100%;
    }
    img {
      height: auto !important;
    }

    h1, h2, h3, h4 {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.4px;
      color: #000000;
      margin-bottom: 14px;
    }

    strong {
      font-weight: 500;
    }

    ul, ol,
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 27px;
      letter-spacing: -0.394549px;
      color: #000000;
      margin-bottom: 27px;
    }
    ol {
      padding-left: 10px;
    }
    p {
      margin-bottom: 6px;
    }

    img {
      display: inline-block;
      max-width: 100%;
      margin: 0 0 15px !important;
    }
    ul {
      list-style: none;
      li {
        list-style: none;
        padding-left: 18px; position: relative;
        margin-bottom: 6px;
        &:before {
          position: absolute; left: 0; top: 0;
          content: '—'; font-size: 13px; color: #000;
        }
      }
    }

    table {
      border-spacing: 0;
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
      font-size: 12px;
      p {
        margin: 0;
        font-size: 14px;
      }
      thead {
        text-align: center;
      }
      td {
        border: 1px solid #9c9b9b;
        padding: 2px 3px;
        text-align: left;
        word-break: break-word;

        &:first-child {
          text-align: left;
        }
      }
    }
  }
}

