.text-snippet {
	&__wrapper {
		position: relative;

		font-weight: 400;
		font-size: 16px;
		line-height: 165%;

		margin-bottom: 10px;

		transition: max-height .1s linear;
		overflow: hidden;

		.text-snippet__out {

		}

		&:after {
			content: '';
			width: 100%;
			height: 50px;
			position: absolute;
			bottom: 0;
			left: 0;
			background: linear-gradient(rgba(255, 255, 255, 0) 16%, #fff 83%, #fff 100%);
			transition: all .1s linear;
		}

		&.active {
			&:after {
				opacity: 0;
			}
		}
	}
}
