.auth-email-form {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;

	&__row {
		width: 100%;
	}

	&__submit-note {
		display: flex;
		gap: 4px;

		span {
			color: #1536A1;
		}
	}
}
