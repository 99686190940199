@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import './CssConstants';
@import './forms';
@import './MobileForms';
@import './modals';

body {
  font-family: $font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

a {
  color: #2e2f30;
  text-decoration: none;
}

*::-webkit-scrollbar-thumb {
  background: #D6D6E8;
  min-height: 30px;
  border-radius: 4px;
  cursor: pointer;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar-track {
  background: rgba(243, 243, 249, 0.25);
  border-left: none;
}

.upper-index {
  font-size: 60%;
  top: -0.55em;
  position: relative;
}

button {
  outline: none;
  background: none;
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;

  &.button-link {
    display: inline-flex;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #1536A1;
  }
}

.color {
  &-success {
    color: rgba(33, 150, 83, 1);
  }
  &-error {
    color: rgba(255, 75, 85, 1);
  }
}

.container {
  max-width: 1294px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: content-box;
}

.page-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__header {
    width: 100%;
    // background: linear-gradient(90deg, #1336A7 -1.28%, #173082 72.04%, #181368 100%);
    position: relative;
    z-index: 10;
  }

  &__content {
    flex: 1;
    padding-top: 32px;
    padding-bottom: 32px;
    overflow-x: hidden;

    h1 {
      font-weight: 700;
      font-size: 36px;
      line-height: 36px;
      letter-spacing: 0.01em;
      color: #343434;
      margin-bottom: 64px;
    }
  }
}

.page-content-section {
  h2 {
    margin: 0 0 25px 0;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 0.01em;
    color: #343434;
    display: flex;
    justify-content: space-between;
  }

  h3 {
    margin-bottom: 25px;
    font-weight: 700;
    font-size: 28px;
    line-height: 145%;
    letter-spacing: 0.01em;
    color: #343434;
    display: flex;
    justify-content: space-between;
  }
}

.message-form-section {
  display: flex;
  gap: 24px;
  align-items: stretch;

  &__col {

    &_1 {
      overflow: hidden;
      flex: 1;
    }

    &_2 {
      width: 420px;
    }
  }
}

.message-form {
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 20px;
    min-height: 100%;
    box-sizing: border-box;
    gap: 8px;
    background: rgba(162, 162, 162, 0.1);
    border-top: 1px solid #F1F3FC;
    border-radius: 8px;

  }
  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #343434;
  }
}

.text-button {
  display: inline-flex;
  gap: 8px;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px 12px;
  gap: 4px;
  height: 32px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;

  color: #000000;

  &_yellow {
    background: rgba(255, 184, 0, 0.8);
    color: #000000;
  }
}

.page-content-section {
  margin-bottom: 64px;
}

.text-gray-40 {
  color: rgba(0, 0, 0, 0.4);
}
.text-success {
  color: rgba(33, 150, 83, 1);
}
.text-error {
  color: rgba(255, 75, 85, 1);
}
.text-gray-80 {
  color: rgba(0, 0, 0, 0.8);
}
.text-gray-60 {
  color: rgba(0, 0, 0, 0.6);
}
.text-600 {
  font-weight: 600;
}

.text-blue {
  color: rgba(21, 54, 161, 1)
}

.text-black {
  color: rgba(0, 0, 0, 1)
}

.gap-4 {
  gap: 4px;
}

.gap-8 {
  gap: 8px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}
.gap-32 {
  gap: 32px;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-0 {
  flex: 0;
}

.flex-2 {
  flex: 2;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.mb-4 { margin-bottom: 4px; }
.mb-6 { margin-bottom: 6px; }
.mb-8 { margin-bottom: 8px; }
.mb-10 { margin-bottom: 10px; }
.mb-12 { margin-bottom: 12px; }
.mb-16 { margin-bottom: 12px; }
.mb-15 { margin-bottom: 15px; }
.mb-20 { margin-bottom: 20px; }
.mb-25 { margin-bottom: 25px; }
.mb-30 { margin-bottom: 30px; }
.mb-35 { margin-bottom: 35px; }

.w-full {
  width: 100%;
}

.w-max-800 {
  max-width: 800px;
}

.decoration-line-through {
  text-decoration: line-through;
}

.two-cols-container {
  display: flex;
  margin-bottom: 50px;

  &__col {
    &_left {
      width: 255px;
    }

    &_right {
      flex: 1;
      padding-left: 20px;
    }
  }
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1031;
  width: 100%;
  height: 2px;
  background: #035aa2;
}

#nprogress .peg {
  position: absolute;
  right: 0;
  display: block;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #035aa2, 0 0 5px #035aa2;
  transform: rotate(3deg) translateY(-4px);
  opacity: 1;
}

#nprogress .spinner,
#nprogress .spinner-icon {
  // display: none;
}


.html-content {
  font-size: 16px;
  line-height: 26px;
  color: #000000;

  * {
    font-family: 'Inter', sans-serif !important;
  }

  a {
    color: #1536A1;

    &:active,
    &:hover {
      color: #2247bd;
    }
  }

  h1, h2, h3, h4 {
    font-size: 15px;
    line-height: 19px;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 15px;
  }

  strong {
    font-weight: 500;
  }

  ul, ol,
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.394549px;
    color: #000000;
    margin-bottom: 24px;
  }
  ol {
    padding-left: 20px;
  }
  img {
    display: inline-block;
    max-width: 100%;
    margin: 0 0 15px !important;
    border: 0 !important;
  }
  ul {
    list-style: none;
    margin-left: 0!important;
    padding-left: 0;
    li {
      list-style: none;
      padding-left: 23px; position: relative;
      margin-bottom: 6px;
      &:before {
        position: absolute; left: 0; top: 0;
        content: '—'; font-size: 16px; color: #000;
      }
    }
  }

  table {
    border-spacing: 0;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    table-layout: fixed;
    p {
      margin: 0;
    }
    thead {
      text-align: center;
    }
    td {
      border: 1px solid #686868;
      padding: 4px 6px;
      text-align: left;

      &:first-child {
        text-align: left;
      }
    }
  }
}
