.mobile-body {
	.products-list-item {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;

		&__top,
		&__bottom {
			width: 100%;
			position: relative;
		}

		&__labels {
			top: 117px;
			width: 100%;
			padding: 8px;
		}

		&__label {
			padding: 0.9px 2.7px;
			gap: 8.97px;
			height: 17.79px;
			border-radius: 3.58974px;
			font-weight: 600;
			font-size: 10.7692px;
			line-height: 145%;
		}

		&__image {
			height: 150px;
			width: 100%;
			margin-bottom: 9px;
		}

		&__title {
			margin-bottom: 4px;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			width: 150px;
			word-break: unset;
		}

		&__price {
			margin-bottom: 4px;
			gap: 8px;

			&-number {
				font-weight: 600;
				font-size: 16px;
				line-height: 22px;
			}

			&-unit {
				font-size: 14px;
				line-height: 12px;
				font-weight: 400;
			}
		}

		&__old-price-number {
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 135%;
			color: #9D9DA5;
		}

		&__reminder {
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
		}
	}
}
