.mobile-body {
	.product-page {
		.page-layout__content {
			padding-top: 0;

			h1 {
				font-style: normal;
				font-weight: 700;
				font-size: 20px;
				line-height: 26px;
				color: #343434;
				margin-bottom: 4px;
			}
		}
	}
	.product-page-part {
		flex-direction: column;
		gap: 20px;
		&__col {
			&_1 {
				width: 100%;
			}

			&_2 {
				width: 100%;
			}
		}

		&__top {
			gap: 16px;
		}

		h2 {
			font-weight: 700;
			font-size: 20px;
			line-height: 26px;
			color: #343434;
		}

		.html-content {
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;

			p, span {
				font-weight: 400 !important;
				font-size: 14px !important;
				line-height: 20px !important;
			}

			p:last-child {
				margin-bottom: 0;
			}
		}
	}

	.order-widget__wrapper {
		gap: 40px;
		min-height: unset;

		&-top {
			flex: unset;
		}

		&-bottom {
			height: auto;
			display: flex;
			align-items: center;
			background: rgba(162, 162, 162, 0.1);
			border-radius: 12px;
			width: 100%;
		}

		& > .order-widget__wrapper-top {
			padding: 0;
			background: none;
			border-radius: 0;
		}
	}
}
