.mobile-body {
	.order-sidebar-widget {
		align-items: center;
		&__icon {
			width: 40px;
			display: flex;
		}

		&__info {
			flex: 1;
			text-align: left;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
		}

		&__title {
			font-size: 14px;
			line-height: 20px;
		}

		&__value {
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
		}
	}
}
