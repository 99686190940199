.mobile-body {
	.breadcrumbs {
		gap: 8px;
		padding: 12px 0;
		overflow-x: auto;

		&__item {
			white-space: nowrap;
			span,
			a {
				font-weight: 400;
				font-size: 14px;
				line-height: 20px;
			}
		}
	}
}
