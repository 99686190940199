.button-checkbox__group {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	gap: 8px;

	& > div {
		margin: 0;
	}
}

.button-checkbox {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px;
	gap: 4px;
	height: 32px;

	background: rgba(0, 0, 0, 0.05);
	border-radius: 4px;

	font-size: 14px;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.8);

	&_checked {
		color: #fff;
		background-color: rgba(21, 54, 161, 1);
	}
}
