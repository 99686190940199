.stocks-list {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 24px;
	padding: 0;
	margin: 0;
	list-style: none;

	&__item {
		min-height: 220px;
		box-sizing: border-box;
		background-size: cover;
		background-repeat: no-repeat;
		border-radius: 8px;

		a {
			display: flex;
			height: 100%;
			width: 100%;
			flex-direction: column;
			justify-content: flex-end;
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 87%);
			border-radius: 8px;
			padding: 16px;
			color: #fff;
		}

		&-content {
			display: flex;
			flex-direction: column;

			span {
				display: block;
			}
		}

		&-title {
			font-weight: 600;
			font-size: 16px;
			line-height: 22px;
			margin-bottom: 9px;
		}

		&-date {
			display: flex;
			align-items: center;
			font-size: 14px;
			line-height: 20px;

			svg {
				margin-right: 5px;
			}
		}
	}
}
