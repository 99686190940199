.rubrics-menu {
	position: relative;
	z-index: 10;
	padding-bottom: 15px;
	background: linear-gradient(90deg, #1336A7 -1.28%, #173082 72.04%, #181368 100%);
	&__list {
		display: flex;
		gap: 20px;
		margin: 0;
		padding: 0;
		list-style: none;

		&-item {
			display: block;

			a {
				color: #fff;
				opacity: .5;
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
			}
		}
	}
	&__wrapper {
		display: flex;
		align-items: center;

		& > a {
			padding: 5px 20px;
			margin-right: 25px;
		}
	}

	.container {
		position: relative;
	}
}

.rubrics-menu-output {
	width: 100%;
	display: flex;

	&__wrapper {
		position: absolute;
		left: 0;
		top: 100%;
		width: 100%;
		box-sizing: border-box;
		background-color: #ffffff;
		display: flex;
		z-index: 10;

		.container {
			display: flex;
			flex: 1;
		}
	}

	&__shadow {
		position: fixed;
		top: 0;
		width: 100%;
		background: rgba(0, 0, 0, 0.5);
		left: 0;
		height: 100%;
		z-index: 9;
		transition: all 0.3s;
	}

	&__left {
		padding: 40px 64px 40px 0;
		border-right: 4px solid rgba(243, 243, 243, 1);

		&-list {
			padding: 0;
			margin: 0;
			display: flex;
			flex-direction: column;
			gap: 4px;
			width: 222px;

			li {
				border-radius: 4px;

				a {
					white-space: nowrap;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					padding: 8px 12px;
					gap: 12px;
					min-height: 40px;
					box-sizing: border-box;
					font-size: 16px;
					line-height: 24px;
					color: #000000;
				}
			}
			.active {
				background: rgba(162, 162, 162, 0.1);
			}
		}
	}
	&__center {
		padding: 40px 64px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 32px;
		flex: 1;

		.rubrics-menu-output__col {
			display: flex;
			flex-direction: column;
			gap: 32px;
		}

		.rubrics-menu-output__item {
			display: flex;
			flex-direction: column;
			gap: 16px;

			& > a {
				font-weight: 700;
				font-size: 16px;
				line-height: 20px;
				color: rgba(0, 0, 0, 0.8);
			}

			ul {
				list-style: none;
				padding: 0;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 8px;

				li {
					list-style: none;
					padding: 0;

					a {

						min-height: 26px;
						font-size: 16px;
						line-height: 26px;
						color: rgba(0, 0, 0, 0.8);

					}
				}
			}
		}
	}
	&__right {
		padding-top: 40px;

		.brands-list {
			min-width: 136px;
			max-height: 400px;
			overflow-y: auto;
		}
	}
}
