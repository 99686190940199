.image-box {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	border-radius: 6px;

	&.with-border {
		border: 0.5px solid rgba(162, 162, 162, 0.4);
	}

	&__bg {
		height: 100%;
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		background: url("../../images/product_bg.svg") 50% / 100% auto no-repeat;
		z-index: 1;
	}

	img {
		max-width: calc(100% - 12px);
		max-height: calc(100% - 12px);
		display: block;
		position: relative;
		z-index: 0;
	}

	&__text {
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		font-weight: 600;
		font-size: 16px;
		line-height: 26px;
		color: #000000;
	}
}
