$font-family: 'Inter', sans-serif;

$main-50: #f2f3f4;
$main-200: #d9dcdd;
$main-500: #00549f;

$blue-100: #e6eef5;
$blue-200: #0975d7;
$blue-300: #0166c1;
$blue-400: #0159a8;
$blue-500: #00549f;
$blue-800: #002b50;

$green-200: #bddc80;
$green-300: #8ad001;
$green-400: #80c101;
$green-500: #7ab800;
$green-700: #568100;

$red-400: #dc2e20;
$red-500: #d52b1e;

$yellow-100: #fdf8ee;
$yellow-300: #fbce80;
$yellow-400: #fabf58;
$yellow-500: #f9b949;

$gray-50: #f2f3f4;
$gray-100: #e6e8e9;
$gray-200: #d9dcdd;
$gray-300: #c0c5c7;
$gray-350: #c2c2c2;
$gray-400: #889399;
$gray-500: #7b8489;
$gray-600: #676e72;
$gray-650: #666;
$gray-700: #414548;
$gray-900: #000;
