.products-filters {
	&__wrapper {

	}
}

.products-filter {
	margin-bottom: 32px;

	&__title {
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 16px;
	}
	&__values {
		&-row {
			margin-bottom: 12px;
			display: flex;
			gap: 6px;
			align-items: center;
		}
	}
}

.products-range-filter {
}
