.mobile-search {
	margin-bottom: 21px;
}

.search-results-block {
	margin-bottom: 20px;

	&__title {
		margin-bottom: 8px;
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		color: #000000;
	}

	&__list {
		gap: 8px
	}

	&__list-item {
		a {
			display: flex;
			gap: 12px;
			align-items: center;
			width: 100%;
		}

		&-title {
			font-size: 16px;
			line-height: 24px;
			color: #000000;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			flex: 1;
		}

		.highlighted {
			font-weight: 600;
			padding: 0;
			background: none;
		}

		&-subtitle {
			display: block;
			font-size: 12px;
			line-height: 16px;
			color: rgba(0, 0, 0, 0.4);
		}

		&-img {
			display: flex;
			align-items: center;
		}
	}
}
