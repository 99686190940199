.contacts-page {
	.requisites {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: #000000;
	}

	.branch-contacts-list {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
}
