.header-contacts {
	.phone {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		padding: 4px;
		height: 28px;
		background: linear-gradient(270deg, #426BEF 0%, #244CCB 100%);
		border-radius: 66px;

		* {
			text-align: center;
			color: #FFFFFF;
		}
	}
}
