.mobile-body {
	.text-snippet {
		.ant-btn.ant-btn-text.st-btn.secondary {
			padding: 0;
			min-width: 0;
			border: 0;

			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			color: #1536A1;
			height: auto;
			background: none;
		}
	}
}
