.rubrics-menu-list {
	gap: 12px;

	&__item {
		display: flex;
		align-items: center;

		button,
		a {
			display: flex;
			align-items: center;
			gap: 12px;
			width: 100%;
		}

		&-title {
			flex: 1;
			text-align: left;
		}
	}

	&__item-img {
		width: 48px;
		height: 48px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		border-radius: 6px;

		&-bg {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			border-radius: 6px;
			background: url("./bg.svg") 50% / 100% auto no-repeat;
		}

		img {
			max-width: 42px;
			max-height: 42px;
			display: block;
			position: relative;
			z-index: 1;
		}
	}
}
