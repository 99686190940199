.status-page {
	.page-layout__content {
		display: flex;
		flex-direction: column;
	}

	.container {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
		flex: 1;
		justify-content: space-between;
		padding: 32px 0;
	}

	&__top {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;
	}

	&__title {
		font-weight: 700;
		font-size: 20px;
		line-height: 26px;
		text-align: center;
		color: #343434;
	}

	&__text {
		font-size: 14px;
		line-height: 20px;
		text-align: center;
		color: #000000;
	}
}
