.mobile-body {
	.slider {
		margin-bottom: 32px;

		.swiper {
			border-radius: 3px;
		}

		&_top {
			margin-bottom: 40px;
			.swiper {
				overflow: visible;
			}
			.swiper-slide {
				height: 220px;
			}
		}

		&_bottom {
			.swiper {
			}

			.swiper-slide {
				height: 200px;
			}
		}
	}

}
