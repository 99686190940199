.mobile-body {
	.product-page-traits {
		display: flex;
		flex-direction: column;
		gap: 8px;
		width: auto;

		&__item {
			flex-direction: column;
			align-items: flex-start;
			gap: 0;
			justify-content: flex-start;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
		}
	}
}
