.doc-icon {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	height: 24px;
	width: 24px;
	padding-top: 5px;
	box-sizing: border-box;

	svg {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 0;
	}

	&__text {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		z-index: 1;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 800;
		font-size: 6px;
		line-height: 135%;
		letter-spacing: 0.03em;
		color: #FFFFFF;
	}
}
