.mobile-body {
	.product-order-widget {
		gap: 16px;

		&__row {
			display: flex;
			gap: 16px;
			flex-direction: column;
		}

		&__group {
			border-radius: 12px;
			background: rgba(162, 162, 162, 0.1);
			padding: 0 16px;
			gap: 0;

			.order-sidebar-widget {
				padding: 16px 0;
				border-bottom: 1px solid rgba(162, 162, 162, 0.2);

				&:last-child {
					border: 0;
				}
			}

			&.no-bg {
				background: none;
				border: 1px solid rgba(162, 162, 162, 0.2);
			}
		}
	}

	.large-price-box {
		gap: 0;
		min-height: unset;

		&__price-value {
			gap: 2px;
			font-weight: 700;
			font-size: 26px;
			line-height: 30px;
		}

		&__old-price {
			display: flex;
			gap: 8px;
			align-items: center;
			font-weight: 400;
			font-size: 12px;
			line-height: 145%;
			color: #9D9DA5;
		}

		.products-list-item__label {
			font-weight: 600;
			font-size: 12px;
			line-height: 145%;
		}
	}

	.units-select {
		gap: 4px;
		margin-bottom: 8px;
	}
}
