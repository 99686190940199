.mobile-body {
	.articles-journal {
		&__list {
			gap: 8px;
			flex-wrap: nowrap;
			overflow-x: auto;
			margin-bottom: -12px;
			padding-bottom: 12px;

			&-item {
				min-width: 307px;
				width: 307px;
				min-height: 200px;

				&_big {
					width: 307px;
					min-height: 200px;
				}

				&-description {
					font-weight: 600;
					font-size: 16px;
					line-height: 22px;
				}

				&-subtitle {
					font-size: 14px;
					line-height: 20px;
				}
			}
		}
	}
}
