.branch-list-item {
	display: flex;
	justify-content: space-between;
	width: 100%;

	&__left {
		display: flex;
		flex-direction: column;
		gap: 4px;
	}

	&__right {
		a {
			display: flex;
			align-items: center;justify-content: center;
			height: 32px;
			width: 32px;
		}
	}

	&__title {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;

		color: #000000;
	}

	&__schedule {
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;

		color: rgba(0, 0, 0, 0.4);
	}

	&__tags {
		display: flex;
		gap: 4px;

		span {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: 4px 8px;
			gap: 4px;

			height: 24px;

			background: rgba(21, 54, 161, 0.1);
			border-radius: 16px;
			font-weight: 700;
			font-size: 12px;
			line-height: 12px;
			leading-trim: both;
			text-edge: cap;

			color: #1536A1;
		}
	}
}
