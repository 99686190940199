.ant-modal-wrap {
	.ant-modal-content {
		background: #FFFFFF;
		border-radius: 8px;
		box-shadow: none;

		.ant-modal-close {
			height: 28px;
			width: 28px;
			top: 7px;
			right: 7px;

			.ant-modal-close-x {
				height: 28px;
				width: 28px;
				line-height: unset;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.ant-modal-header {
			padding: 36px 40px 20px;
			background: transparent;
			border-bottom: 0;
			border-radius: 0;

			.ant-modal-title {
				font-weight: 700;
				font-size: 26px;
				line-height: 30px;
				color: #000000;
			}
		}
		.ant-modal-body {
			padding: 0 38px 20px;
		}
	}
}
