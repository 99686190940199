.documents-list {
	display: flex;
	flex-direction: column;
	gap: 12px;

	&__item {
		display: flex;
		gap: 4px;

		&-right {
			display: flex;
			flex-direction: column;
			gap: 4px;
		}

		&,
		&:active,
		&:visited {
			.documents-list__item {
				&-title {
					font-weight: 400;
					font-size: 14px;
					line-height: 20px;
					color: #000000;
				}
				&-size {
					font-weight: 400;
					font-size: 12px;
					line-height: 16px;
					color: rgba(0, 0, 0, 0.4);
				}
			}
		}
	}
}
