.mobile-body {
	.rubrics-list {
		grid-template-columns: repeat(2, 1fr);
		gap: 8px;

		&__item {
			width: auto;
			height: 175px;
			padding: 14px 4px;

			a {
				img {
					height: 105px;
					width: 105px;
					object-fit: contain;
					margin-bottom: 2px;
					display: block;
				}
			}
			&-title {
				font-weight: 600;
				font-size: 14px;
				line-height: 20px;
				display: flex;
				flex: 1;
				align-items: flex-end;
				text-align: center;
				white-space: unset;
				text-overflow: unset;
				overflow: unset;
				justify-content: center;
				width: 100%;
				color: rgba(0, 0, 0, 0.8);
			}
		}
	}
}
