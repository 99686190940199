.cart-position {
	display: flex;
	gap: 16px;
	width: 100%;
	box-sizing: border-box;
	padding: 16px 0 0;
	border-top: 1px solid rgba(162, 162, 162, 0.2);

	&__image {
		width: 100%;
		height: 150px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__unavailable-price {
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: rgba(0, 0, 0, 0.4);
		text-align: right;
	}

	&__actions {
		display: flex;
		justify-content: flex-end;
	}

	&__offer-name {
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: #000000;
	}

	&__brand-name {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: rgba(0, 0, 0, 0.6);
	}

	&__price {
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 135%;
		color: #000000;
		display: flex;
		gap: 4px;
		justify-content: flex-end;
		align-items: center;

		.old-price {
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 135%;
			color: #9D9DA5;
		}
		.price-units {
			font-weight: 400;
			font-size: 12px;
			line-height: 14px;
		}
	}

	&__col {
		box-sizing: border-box;

		&_checkbox {
			width: 20px;
		}

		&_img {
			width: 150px;
		}

		&_right {
			flex: 1;
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			padding: 0;
			gap: 32px;
		}

		&_description {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 4px;
		}

		&_qty {
			width: 100px;
		}

		&_last {
			min-width: 200px;
			display: flex;
			gap: 9px;
			flex-direction: column;
		}
	}

	//&_unavailable {
	//	.cart-position {
	//		&__col {
	//			&_checkbox {
	//				opacity: .5;
	//			}
	//
	//			&_img {
	//				opacity: .5;
	//			}
	//
	//			&_qty {
	//				display: none;
	//			}
	//		}
	//		&__offer-name,
	//		&__brand-name {
	//			color: rgba(0, 0, 0, 0.4);
	//		}
	//	}
	//	.qty-change__wrapper {
	//		display: none;
	//	}
	//}
}
