.mobile-body {
	.cart-page {
		.page-layout__content {
			padding: 24px 0;
		}
		h1 {
			margin-bottom: 24px;
		}

		h2 {
			padding-top: 16px;
		}

		&__col {
			display: flex;
			flex-direction: column;
			gap: 16px;
		}

		&__position-actions {
			display: flex;
			align-items: center;
			flex-direction: row-reverse;
			justify-content: space-between;
			gap: 16px;

			.st-check {
				flex-direction: row-reverse;
			}
		}
	}
	.cart-page-content {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}
}
