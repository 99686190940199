.product-show-images {
	display: flex;
	gap: 6px;
	height: 100%;

	&__output {
		min-height: 465px;
		max-height: 490px;
		height: 100%;
		display: flex;
		flex: 1;
		align-items: center;
		justify-content: center;
		position: relative;

		&-bg {
			height: 100%;
			width: 100%;
			position: absolute;
			left: 0;
			top: 0;
			display: block;
			background: url("./bg.svg") 50% / cover no-repeat;
			border-radius: 12px;
			z-index: 1;
		}

		img {
			max-width: 95%;
			max-height: 95%;
			display: block;
		}
	}

	&__preview-scroll {
		min-height: 465px;
		max-height: 490px;
		height: 100%;
		overflow-y: auto;
	}

	&__preview {
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding-right: 4px;

		.image-box {
			transition: all .1s linear;
			border: 0 solid transparent;
		}
		.image-box__bg {
			cursor: pointer;
		}

		.active {
			border: 1px solid rgba(255, 184, 0, 0.8);
			.image-box__bg {
				cursor: default;
			}
		}

		img {
			cursor: pointer;
			box-sizing: border-box;
			transition: all .1s linear;
		}
	}
}
