.select-city {
	position: relative;
	min-width: 140px;

	.select-city__selector {
		opacity: .5;
		display: inline-flex;
		gap: 4px;
		align-items: center;
	}

	&__dropdown {
		display: none;
		position: absolute;
		top: 100%;
		left: 0;

		padding: 8px;
		background: #fff;
		flex-direction: column;
		gap: 8px;

		button {
			padding: 2px 8px;
		}
	}

	&_open {
		.select-city {
			&__dropdown {
				display: flex;
			}
		}
	}

	&__modal {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		flex-direction: column;
		gap: 16px;

		&-item {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			cursor: pointer;
			color: #000000;

			&_active {
				font-weight: 600;
			}
		}
	}

	&__search {
		.mobile-search-string__search-row {
			margin: 0;
		}
	}
}
