.mobile-body {
	.footer {
		padding-top: 0;

		&__wrapper {
			display: flex;
			flex-direction: column;
			gap: 0;
		}

		&__col {
			width: 100%;
		}

		&__left {
			&-top {
				margin-bottom: 0;
				display: flex;
				flex-direction: column;
			}
		}

		&__box {
			&-title {
				font-weight: 700;
				font-size: 18px;
				line-height: 135%;
				margin-bottom: 10px;
			}

			&-item {
				margin-bottom: 7px;
				font-weight: 400;
				font-size: 16px;
				line-height: 165%;
			}
		}

		&__vk-link {
			opacity: 1;
			img {
				width: 157px;
				display: block;
			}
		}
	}
}
