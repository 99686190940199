.st-btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	user-select: none;
	border: none;
	padding: 12px 10px;
	gap: 5px;

	// min-width: 60px;
	height: 43px;
	border-radius: 6px;

	font-weight: 500;
	font-size: 14px;
	line-height: 135%;

	&:hover,
	&:active,
	&:focus {
		color: #fff;
	}

	&.secondary {
		background: #F1F3FC;
		color: #1536A1;
	}

	&.primary {
		background: rgba(255, 184, 0, 0.8);
		color: #000;

		&.disabled,
		&[disabled] {
			&,
			&:hover,
			&:active,
			&:focus {
				background: rgba(162, 162, 162, 0.2);
				color: rgba(0, 0, 0, 0.2);
			}
		}
	}

	&.clean {
		border: 1px solid rgba(94, 125, 226, 0.3);
		background: #fff;
		color: #1536A1;
	}

	&.lg {
		padding: 16px 20px;
		gap: 8px;
		height: 56px;
		border-radius: 12px;
		font-weight: 500;
		font-size: 18px;
		line-height: 24px;
	}

	&.md {
		border-radius: 4px;
		height: 40px;
		font-size: 14px;
		line-height: 16px;
	}

	&.l {
		height: 48px;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		padding: 20px 16px;
		gap: 8px;
		border-radius: 8px;
	}

	&.ant-btn-sm,
	&.sm {
		padding: 8px 12px;
		gap: 4px;
		height: 32px;
		border-radius: 4px;
		font-weight: 500;
		font-size: 14px;
		line-height: 12px;
	}

	&.disabled,
	&[disabled] {
		&,
		&:hover,
		&:active,
		&:focus {
			opacity: .5;
			cursor: not-allowed;
		}
	}
}
