.large-switcher {
	display: flex;

	&__wrapper {
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		flex: 0;
		align-items: flex-start;
		position: relative;
		height: 48px;
	}

	&__marker {
		position: absolute;
		height: 100%;
		top: 0;
		transition: all 0.2s ease-in-out;
		background: #1536A1;
		border-radius: 8px;
	}

	&__options {
		display: flex;
		position: relative;
		z-index: 1;
		height: 100%;
		border: 1px solid rgba(162, 162, 162, 0.4);
		border-radius: 8px;
	}

	&__option {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		height: 100%;
		padding: 10px 16px;
		min-width: 192px;
		transition: all 0.1s ease-in-out;
		color: rgba(0, 0, 0, 0.8);
		cursor: pointer;

		span {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&.active {
			color: #fff;
			cursor: default;
		}
	}
}
