.branch-offer-availability {
	display: flex;
	flex-direction: column;
	gap: 16px;

	&-actions {
		display: flex;
		align-items: center;
		gap: 8px;

		span {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 20px;

			color: rgba(0, 0, 0, 0.4);
		}

		.ant-switch-checked + span {
			color: #000;
		}
	}
	&-list {
		display: flex;
		flex-direction: column;
		gap: 16px;

		&-item {
			display: flex;
			flex-direction: column;
			gap: 4px;

			&__reminder {
				font-weight: 600;
				font-size: 16px;
				line-height: 24px;
				color: #219653;
			}
		}
	}
}
