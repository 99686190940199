.breadcrumbs {
	list-style: none;
	padding: 16px 0 9px;
	margin: 0;
	display: flex;
	align-items: center;
	gap: 12px;

	&__item {
		display: block;

		&:first-child {
			margin-right: -6px;
		}

		&:last-child {
			span,
			a {
				color: #000;
			}
		}

		span,
		a {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: rgba(0, 0, 0, 0.6);
		}
	}
}
