.footer__box {
	&-title {
		font-weight: 700;
		font-size: 18px;
		line-height: 135%;
		margin-bottom: 10px;
		display: flex;
		justify-content: space-between;
		align-items: baseline;
	}

	&-item {
		margin-bottom: 7px;
		font-weight: 400;
		font-size: 16px;
		line-height: 165%;
		opacity: .55;
	}
}
