.products-sort-and-filters-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
}
.mobile-filters {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #000000;
	display: flex;
	align-items: center;
	gap: 6px;

	&__header {
		padding-right: 28px;
		display: flex;
		justify-content: space-between;
		align-items: baseline;

		button {
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			color: #1536A1;
		}
	}
}
