.mobile-body {
	.qty-change {
		width: 174px;
		padding: 4px 12px;
		.ant-input-number {
			border: 0;
			height: 100%;
			min-width: 40px;
			flex: 1;
			background: transparent;

			&,
			&:focus,
			&:focus-within {
				box-shadow: none;
				outline: none;
				border: none;
			}

			&-input-wrap {
				height: 100%;
				width: 100%;
			}

			input {
				height: 100%;
				width: 100%;
				padding: 0;
				text-align: center;
			}
		}
	}
}
