.mobile-search-string {
	&__clear {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: #1536A1;
		overflow: hidden;
		max-width: 0;
		transition: max-width .1s linear;

		&.active {
			max-width: 70px;
		}
	}

	&__search {
		background: transparent;
		outline: none;
		border: 0;
		box-shadow: none;
		width: 100%;
		height: 40px;
		padding: 2px 12px 2px 44px;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;


		&-wrapper {
			position: relative;
			background: rgba(162, 162, 162, 0.1);
			border-radius: 20px;
			flex: 1;
		}

		&-row {
			display: flex;
			align-items: center;
			gap: 12px;
			margin-bottom: 12px;

			&.desktop {
				.mobile-search-string {
					&__clear {
						display: none;

						&.active {
							display: inline-flex;
						}
					}

					&__search {
						font-weight: 400;
						font-size: 16px;
						line-height: 20px;
						height: 44px;
					}
				}
			}
		}

		&-icon {
			position: absolute;
			left: 12px;
			top: 8px
		}
	}

}
.hidden-submit {
	height: 0;
	width: 0;
	outline: none;
	box-shadow: none;
	border: 0;
	display: block;
}
