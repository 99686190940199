.brands-list {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	gap: 20px;
	padding: 0;
	margin: 0;
	list-style: none;

	&.column {
		display: flex;
		flex-direction: column;
		gap: 12px;

		.brands-list {
			&__item {
				height: 70px;
				min-height: 70px;
			}
		}
	}

	&__item {
		height: 100px;
		position: relative;
		font-weight: 600;
		font-size: 19px;

		&-bg {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;

			background: url("../../images/brand_bg.svg") 50% / cover no-repeat;
			border-radius: 8px;
			z-index: 1;
		}

		a {
			display: flex;
			position: relative;
			z-index: 1;
			height: 100%;
			width: 100%;
			align-items: center;
			justify-content: center;

			img {
				max-height: 90%;
				max-width: 90%;
			}
		}
	}
}
