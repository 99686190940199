.cart-page-info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px;
	gap: 16px;
	width: 100%;
	background: rgba(162, 162, 162, 0.1);
	border-radius: 12px;

	&__top {
		display: flex;
		flex-direction: column;
		gap: 8px;

		width: 100%;
	}

	&__bottom {
		width: 100%;
	}

	&__item {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		&-key {
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			color: #000000;
		}

		&-value {
			text-align: right;
			font-weight: 600;
			font-size: 14px;
			line-height: 20px;
			color: #000000;
		}

		&_large {
			.cart-page-info__item {
				&-key {
					font-weight: 700;
					font-size: 20px;
					line-height: 26px;
				}

				&-value {
					font-weight: 700;
					font-size: 20px;
					line-height: 26px;
				}
			}
		}
		&_discount {
			.cart-page-info__item {
				&-value {
					color: #50BC47;
				}
			}
		}
	}
}
