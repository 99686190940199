.order-page {
}

.order-form {
	display: flex;
	flex-direction: column;
	gap: 40px;

	&__section {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	&__subsection {
		display: flex;
		flex-direction: column;
		gap: 20px;
		padding: 20px;
		background: rgba(162, 162, 162, 0.1);
		border-radius: 12px;

		&-title {
			display: flex;
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 20px;
			color: #000000;
			justify-content: space-between;
		}

		&-content {

		}
	}
}
