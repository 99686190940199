.children-rubrics-list {
	display: flex;
	flex-direction: column;
	margin: 0 0 40px;
	padding: 0;
	list-style: none;
	gap: 12px;

	&__item {
		display: flex;

		button,
		a {
			display: flex;
			align-items: center;
			width: 100%;
			gap: 12px;
		}

		&-title {
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			color: rgba(0, 0, 0, 0.8);
			flex: 1;
			text-align: left;
		}

		&-image {

		}

		&-count {
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			text-align: right;
			color: rgba(0, 0, 0, 0.4);
			width: 30px;
		}
	}
}
