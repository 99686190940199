:root {
	--grid-border: 1px solid #ccc;
}

.products-list {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	position: relative;
	row-gap: 40px;
	column-gap: 24px;
	z-index: 1;

	&_cols-4 {
		grid-template-columns: repeat(4, 1fr);
	}
	&_cols-5 {
		grid-template-columns: repeat(5, 1fr);
	}
	&_cols-6 {
		grid-template-columns: repeat(6, 1fr);
	}
	&_cols-7 {
		grid-template-columns: repeat(7, 1fr);
	}
}
