.cart-page {
	&__col {
		display: flex;
		flex-direction: column;
		gap: 40px;
	}

	&__section {
		h2 {
			margin-bottom: 10px;
		}
	}

	&__position-actions {
		display: flex;
		align-items: center;
		gap: 24px;
	}
}
.cart-page-content {
	display: grid;
	grid-template-columns: 1fr 400px;
	gap: 64px;
}
