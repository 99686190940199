$slider_height: 220px;
.mobile-product-images {
	margin-bottom: 14px;

	.swiper {
		height: calc($slider_height + 23px);
		margin: 0 -16px;
		.swiper-slide {
			height: $slider_height;
			.slide {
				height: 100%;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;

				&__bg {
					height: 100%;
					width: 100%;
					position: absolute;
					left: 0;
					top: 0;
					display: block;
					background: url("./bg.svg") 50% / cover no-repeat;
					z-index: 1;
				}

				img {
					display: block;
					max-width: 90%;
					max-height: 90%;
				}
			}
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		display: none;
	}
	.swiper-pagination {
		bottom: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;

		.swiper-pagination-bullet {
			margin: 0;
			padding: 0;
			width: 4px;
			height: 4px;
			background: #D9D9D9;
			border-radius: 100%;
			transition: width .1s linear;

			&-active {
				width: 16px;
				height: 4px;
				background: linear-gradient(270deg, #FF9900 0%, #FF4B55 100%);
				border-radius: 55px;
			}
		}
	}
}
