.search-bar {
	position: relative;

	&__search-button {
		position: absolute;
		left: 12px;
		top: 8px;
		z-index: 11;
	}

	&__close-button {
		position: absolute;
		right: 16px;
		top: 16px;
		z-index: 11;
	}

	input {
		border: 0;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 8px 12px 8px 53px;
		gap: 8px;
		position: relative;
		z-index: 10;
		height: 48px;

		background: rgba(255, 255, 255, 0.2);
		border-radius: 24px;
		color: #fff;
		font-family: 'Inter', sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 20px;

		::placeholder {
			color: rgba(255, 255, 255, 0.4);
		}
		&:hover {
			border-color: #fff;
		}
		&:focus,
		&.ant-input-focused {
			background: rgba(255, 255, 255, 0.2);
			border-radius: 24px;
			border: 0;
			box-shadow: none;
			outline: 0;
		}

		&.white-bg {
			&,
			&:focus,
			&.ant-input-focused {
				background: #fff;
				color: rgba(0, 0, 0, .8);
			}
		}
	}
}

.search-bar__out {
	position: absolute;
	top: calc(100% + 12px);
	left: -12px;
	width: 100%;
	background: #fff;
	z-index: 10;
	color: #000;
	padding: 16px;
	border-radius: 8px;
	box-sizing: content-box;
	display: flex;
	flex-direction: column;
	gap: 20px;

	&-overlay {
		z-index: 9;
		background: rgba(0, 0, 0, .4);
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	.search-bar-results {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0px;
		gap: 8px;

		&__title {
			font-weight: 600;
			font-size: 14px;
			line-height: 20px;
			color: #000000;
		}

		&__list {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 0;
			gap: 8px;
			list-style: none;

			&-item {
				a {
					display: flex;
					gap: 12px;
					align-items: center;
					width: 100%;
				}

				&-title {
					font-size: 16px;
					line-height: 24px;
					color: #000000;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					flex: 1;
				}

				.highlighted {
					font-weight: 600;
					padding: 0;
					background: none;
				}

				&-subtitle {
					display: block;
					font-size: 12px;
					line-height: 16px;
					color: rgba(0, 0, 0, 0.4);
				}

				&-img {
					display: flex;
					align-items: center;
				}
			}
		}
	}
}
