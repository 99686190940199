.select-city-mobile {
	&__selector {
		display: flex;
		align-items: center;
		gap: 4px;
	}

	&__list {
		gap: 15px
	}
}
