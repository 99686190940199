.mobile-body {
	.reviews-list {
		display: flex;
		flex-direction: column;
		gap: 20px;
		max-width: 100%;

		&_inline {
			flex-direction: row;
			overflow-x: auto;
			margin: 0 -16px -12px;
			padding: 0 16px 12px;
			box-sizing: content-box;

			.reviews-list {
				&__item {
					min-width: 310px;
				}
			}
		}

		&__item {
			padding: 16px;
			gap: 8px;
			background: #F4F4F4;
			border-radius: 8px;


			&-author {
				font-size: 14px;
				line-height: 20px;
			}

			&-date {
				font-size: 12px;
				line-height: 16px;
			}

			&-content {
				font-size: 14px;
				line-height: 20px;
			}
		}
	}

}
