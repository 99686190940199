.reviews-list {
	display: flex;
	flex-direction: column;
	gap: 24px;
	max-width: 800px;

	&__item {
		display: flex;
		flex-direction: column;
		gap: 8px;

		&-top {
			display: flex;
			justify-content: space-between;
		}

		&-author {
			font-weight: 600;
			font-size: 16px;
			line-height: 22px;
			color: #000000;
		}

		&-date {
			font-size: 14px;
			line-height: 24px;
			color: #9D9DA5;
		}

		&-stars {
			display: flex;
			align-items: center;
			padding: 0 4px;
		}

		&-content {
			font-size: 16px;
			line-height: 28px;
			color: #000000;
		}
	}
}
