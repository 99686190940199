.rubric-content {
	.rubric-content__header {
		margin-bottom: 40px;

		h2 {
			font-weight: 700;
			font-size: 28px;
			line-height: 145%;
			letter-spacing: 0.01em;
			color: #343434;
		}
	}
}
