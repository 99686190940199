.children-rubrics-bricks {
	display: grid;
	grid-template-columns: repeat(auto-fill, 200px);
	gap: 18px;
	padding: 0;
	margin: 0 0 64px;
	list-style: none;

	&__item {
		width: 200px;
		height: 200px;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 8px;

		&-bg {
			background: rgba(162, 162, 162, 0.1);
			border-radius: 8px;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}

		a {
			display: flex;
			flex-direction: column;
			height: 100%;
			width: 100%;
			align-items: center;
			justify-content: flex-start;
			position: relative;
			padding: 24px 8px 0;

			img {
				height: 128px;
				width: 128px;
				object-fit: contain;
				margin-bottom: 16px;
				display: block;
			}
		}
		&-title {
			font-weight: 600;
			font-size: 14px;
			line-height: 20px;
			color: #343434;
			display: block;
			width: 100%;
			text-align: center;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}
