.mobile-body {
	.burger-menu-options.burger-menu-links {
		gap: 16px;
		margin-top: 16px;
		padding: 16px 0;
		border-top: 1px solid rgba(162, 162, 162, 0.2);

		.burger-menu-option {
			button,
			a {
				display: flex;
				align-items: center;
				gap: 12px;
			}
		}
	}
}
