.paginator {
	&__wrapper {
		padding: 16px 0;
		margin-bottom: 24px;
	}

	&__button {
		cursor: pointer;
		height: 40px;
		min-width: 40px;
		background: none;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 12px;

		border: 0;
		background: rgba(0, 0, 0, 0.05);
		border-radius: 4px;
		font-size: 14px;
		line-height: 20px;
		color: rgba(0, 0, 0, 0.8);


		&_current {
			cursor: default;
			border: 1px solid rgba(255, 184, 0, 0.8);
			background-color: #fff;
		}
	}

	&__buttons {
		display: inline-flex;
		gap: 8px;
		flex-wrap: wrap;

		.paginator {
			&__button {
			}
		}
	}
}
