.mobile-body {
	.large-switcher {
		width: 100%;

		&__wrapper {
			width: 100%;
			flex: 1;
		}

		&__options {
			justify-content: stretch;
			width: 100%;
			flex: 1;
		}

		&__option {
			font-size: 16px;
			line-height: 24px;
			height: 100%;
			min-width: unset;
			flex: 1;
		}
	}
}
