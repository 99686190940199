.order-sidebar-widget {
	display: flex;
	align-items: stretch;
	width: 100%;
	gap: 12px;

	&__icon {
		width: 40px;
		display: flex;
	}

	&__info {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}

	&__title {
		font-size: 14px;
		line-height: 20px;
	}

	&__value {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
	}
}
