.mobile-body {
	.brands-list {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 20px;
		padding: 0;
		margin: 0;
		list-style: none;

		&_mobile-inline {
			display: flex;
			gap: 8px;
			overflow-x: auto;
			margin: 0 -16px -12px;
			padding: 0 16px 12px;

			.brands-list__item {
				height: 75px;
				min-width: 146px;
			}
		}
	}
}
