.auth-email-form {
	.otp-input {
		& > div {
			display: grid !important;
			grid-template-columns: repeat(6, 1fr);
			gap: 8px;

			input {
				box-sizing: border-box;

				/* Auto layout */
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 8px 12px;
				gap: 8px;

				width: 100% !important;
				height: 48px;
				text-align: center;
				outline: none;
				border-radius: 8px;

				&,
				&:focus {
					background: rgba(162, 162, 162, 0.1);
					border-color: transparent;
				}

				flex: none;
				order: 0;
				flex-grow: 1;

				font-family: 'Inter';
				font-style: normal;
				font-weight: 600;
				font-size: 20px;
				line-height: 24px;
				user-select: none;

				&::selection {
					color: #000;
					background: transparent;
				}

				color: #000000;

				&.with-value {
					&,
					&:focus {
						background: #FFFFFF;
						border: 2px solid rgba(255, 184, 0, 0.8);
					}
				}
			}
		}

		&.has-error {
			& > div {
				input {
					&,
					&:focus {
						background: #FFFFFF;
						border: 2px solid #D13A3A;
					}
				}
			}
		}

		&.success {
			& > div {
				input {
					&,
					&:focus {
						background: #FFFFFF;
						border: 2px solid #219653;
					}
				}
			}
		}
	}
}
