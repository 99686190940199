.mobile-body {
	.ant-input {
		height: 40px;

		&,
		&:focus {
			border-radius: 4px;
		}
	}

	.ant-input-affix-wrapper {
		&,
		&:focus-within {
			border-radius: 4px;
		}
	}

	.user-form {
	}
}
