.burger-menu {
	&__toggle {
		height: 32px;
		width: 32px;
		align-items: center;
		display: flex;
		justify-content: center;

		img {
			display: block;
		}
	}
}
