.mobile-body {
	.blocks-with-icons {
		&-list {
			justify-content: flex-start;
			gap: 8px;
			overflow-x: auto;
			margin: 0 0 -5px 0;
			padding-bottom: 12px;
		}

		&-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			position: relative;
			width: 70px;

			.icon- {
				height: 52px;
				width: 60px;
			}
			.content- {
				.title- {
					font-weight: 400;
					font-size: 12px;
					line-height: 14px;
					text-align: center;
				}
			}
		}
	}
}
