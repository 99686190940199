.mobile-body {
	.products-list {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		position: relative;
		row-gap: 24px;
		column-gap: 8px;

		&_cols-4 {
			grid-template-columns: repeat(2, 1fr);
		}
		&_cols-5 {
			grid-template-columns: repeat(2, 1fr);
		}
		&_cols-6 {
			grid-template-columns: repeat(2, 1fr);
		}
		&_cols-7 {
			grid-template-columns: repeat(2, 1fr);
		}

		&.mobile-inline {
			display: flex;
			overflow-x: auto;
			margin: 0 -16px -12px;
			padding: 0 16px 12px;

			.products-list-item {
				min-width: 150px;
				width: 150px;
				&__image {
					width: 100%;
					margin-bottom: 9px;
				}
			}
		}
	}
}
