.mobile-body {
	.order-page {
	}
	.order-form {
		display: flex;
		flex-direction: column;
		gap: 40px;
	}

	.mobile-order-form {
		&__section {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			border-radius: 12px;
			padding: 0 16px;
			background: rgba(162, 162, 162, 0.1);

			&-item {
				display: flex;
				width: 100%;
				align-items: center;
				gap: 12px;
				padding: 16px 0;
				border-bottom: 1px solid rgba(162, 162, 162, 0.2);

				&-left {
					flex: 1;
					width: 75%;
					text-align: left;
				}

				&-value {
					font-size: 14px;
					line-height: 20px;
					color: #000000;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					width: 100%;
					display: block;
				}

				&-label {
					font-weight: 400;
					font-size: 14px;
					line-height: 20px;
					color: rgba(0, 0, 0, 0.4);
				}
			}
			.mobile-order-form__section-item {
				&:last-child {
					border: 0;
				}
			}
		}
	}
}
