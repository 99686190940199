.reviews-button {
	display: flex;
	align-items: center;
	gap: 8px;

	&__rating {
		display: flex;
		align-items: center;
		font-size: 16px;
		line-height: 24px;
		color: #000000;
	}

	&__link {
		color: #1536A1;
		cursor: pointer;
	}
}
