.products-list-item {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 195px;

	&__top,
	&__bottom {
		width: 100%;
		position: relative;
	}

	&__labels {
		display: flex;
		gap: 3.6px;
		position: absolute;
		top: 157px;
		width: 100%;
		padding: 10px;
		box-sizing: border-box;
		left: 0;
	}

	&__label {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 3px 2.7px 1px;
		height: 18px;
		border-radius: 3.6px;
		color: #fff;
		font-weight: 600;
		font-size: 10.8px;
		line-height: 145%;
		text-transform: uppercase;

		&_discount {
			background: linear-gradient(90deg, #FF1521 0%, #F79D1C 156.94%);
		}

		&_new {
			background: linear-gradient(90deg, #03A845 0%, #16C83D 156.94%);
		}

		&_hit {
			background: linear-gradient(90deg, #03A845 0%, #16C83D 156.94%);
		}

		&_stock {
			background: linear-gradient(89.87deg, #18A9D7 0.1%, #3BC3EE 113.85%);
		}
	}

	.lazy-load-image-background {
		height: 100%;
		width: 100%;
		display: flex !important;
		align-items: center;
		justify-content: center;

		img {
			display: none;
		}

		&.lazy-load-image-loaded {
			background: none;
			img {
				display: block;
			}
		}
	}
	&__image {
		height: 195px;
		width: 100%;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		margin-bottom: 13px;

		&-bg {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;

			background: url("../../../images/product_bg.svg") 50% / cover no-repeat;
			border-radius: 8px;
		}
		img {
			display: block;
			max-height: 80%;
			max-width: 80%;
		}
	}

	&__title {
		margin-bottom: 7px;
		word-break: break-all;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
	}

	&__price {
		width: 100%;
		text-align: left;
		margin-bottom: 7px;
		gap: 8px;
		display: flex;
		align-items: baseline;

		&-number {
			font-style: normal;
			font-weight: 700;
			font-size: 18px;
			line-height: 135%;
			color: #000000;
		}

		&-unit {
			font-size: 14px;
		}
	}

	&__old-price-number {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 135%;
		color: #9D9DA5;
	}

	&__reminder {
		width: 100%;
		text-align: left;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		& > span:first-child {
			color: #9D9DA5;
			margin-right: 3px;
		}
	}

	&__actions {
		display: flex;
		width: 100%;
		align-items: flex-end;
		padding-top: 6px;
	}
}
