.product-page-traits {
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 400px;

	&__item {
		display: flex;
		align-items: flex-start;
		gap: 24px;
		justify-content: space-between;
		font-weight: 400;
		font-size: 16px;
		line-height: 28px;
	}

	&__title {
		color: rgba(0, 0, 0, 0.6);
	}

	&__value {
		color: rgba(0, 0, 0, 1);
		text-align: right;
	}
}
