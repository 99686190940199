.qty-change {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 0;
	width: 100px;
	height: 40px;

	background: rgba(162, 162, 162, 0.1);
	border-radius: 4px;

	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	button {
		box-sizing: border-box;
		height: 100%;
		display: flex;
		align-items: center;
		line-height: 1;
		border: 0;
		padding: 0;
		width: 24px;
		justify-content: center;

		&:disabled {
			opacity: 0.4;
			cursor: not-allowed;
		}
	}
	.ant-input-number {
		border: 0;
		height: 100%;
		min-width: 40px;
		flex: 1;
		background: transparent;

		&,
		&:focus,
		&:focus-within {
			box-shadow: none;
			outline: none;
			border: none;
		}

		&-input-wrap {
			height: 100%;
			width: 100%;
		}

		input {
			height: 100%;
			width: 100%;
			padding: 0;
			text-align: center;
		}
	}
}
