.mobile-body {
	.stocks-list {
		display: flex;
		gap: 8px;
		overflow-x: auto;
		padding-bottom: 12px;
		margin-bottom: -12px;

		&__item {
			min-height: 200px;
			width: 85vw;
			min-width: 307px;

			&-title {
				font-weight: 600;
				font-size: 16px;
				line-height: 22px;
			}

			&-date {
				font-weight: 400;
				font-size: 14px;
				line-height: 20px;
				color: #FFFFFF;

				svg {
					margin-right: 5px;
				}
			}
		}
	}
}
