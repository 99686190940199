.products-sort {
	display: flex;
	padding: 6px 0;
	align-items: center;

	&__title {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: #000000;
		display: flex;
		align-items: center;
		gap: 4px;
	}
}

$sortPartHeight: 2px;

.sort-icon {
	display: block;
	width: 7.3px;
	height: $sortPartHeight;
	background: #000;
	position: relative;

	&:before, &:after {
		position: absolute;
		height: $sortPartHeight;
		content: '';
		left: 0;
		background: #000; transition: all .1s linear;
	}

	&:before {
		top: -3.7px; width: 4px;
	}

	&:after {
		bottom: -3.7px; width: 10.6px;
	}

	&_desc {
		&:before {
			width: 10.6px;
		}
		&:after {
			width: 4px;
		}
	}

	&__wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 16px;
		width: 16px;
	}
}
