.footer {
	padding: 40px 0;
	color: #fff;
	background: linear-gradient(90deg, #1336A7 -1.28%, #173082 72.04%, #181368 100%);

	a {
		color: #fff;
		opacity: .55;
	}

	&__wrapper {
		display: grid;
		grid-template-columns: 1fr 189px;
		gap: 60px;
	}

	&__col {
		&_3 {
			width: 280px;
			justify-self: flex-end;
		}
	}

	&__left {
		&-top {
			margin-bottom: 25px;
			display: grid;
			grid-template-columns: 220px 220px 1fr;
		}

		&-bottom {
			font-weight: 400;
			font-size: 10px;
			line-height: 135%;
			color: #FFFFFF;
			opacity: 0.55;

			p {
				margin: 0;
				padding: 0;
			}
		}
	}
}

.qr-code {
	&__widget {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 15px 10px;
		border: 1px solid rgba(255, 255, 255, 0.5);
		border-radius: 6px;

		img {
			display: block;
		}

		&-title {
			font-weight: 400;
			font-size: 12px;
			line-height: 145%;
			text-align: center;
			color: #FFFFFF;
			opacity: 0.7;
			margin-bottom: 17px;
		}

		&-code {
			display: flex;
			align-items: center;
			margin-bottom: 17px;
		}

		&-link {
			display: flex;
			justify-content: center;
		}
	}
}
