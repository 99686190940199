$base-color: rgba(0, 0, 0, .07);
$shine-color: rgba(0, 0, 0, .025);
$transparent: rgba(0, 0, 0, 0);
$animation-duration: 1s;

@mixin background-gradient {
	background-image: linear-gradient(90deg, $base-color 0, $shine-color 40px, $base-color 80px);
	background-size: 600px;
}

@mixin lite-background-gradient {
	background-image: linear-gradient(90deg, $transparent 0, $shine-color 40px, $transparent 80px);
	background-size: 600px;
}

.products-list-item_skeleton {

	.products-list-item {
		&__image {
			animation: shine-lines $animation-duration infinite linear;
			@include lite-background-gradient;
			border-radius: 8px;
		}

		&__price {
			&-number {
				display: block;
				width: 50%;
				height: 22px;
				border-radius: 2px;
				animation: shine-lines $animation-duration infinite linear;
				@include background-gradient;
			}
		}

		&__reminder {
			span {
				display: block;
				width: 75%;
				height: 17px;
				border-radius: 2px;
				animation: shine-lines $animation-duration infinite linear;
				@include background-gradient;
			}
		}

		&__title {
			display: flex;
			flex-direction: column;
			gap: 4px;

			&-line {
				display: block;
				height: 16px;
				border-radius: 2px;
				animation: shine-lines $animation-duration infinite linear;
				@include background-gradient;

				&:last-child {
					width: 65%;
				}
			}
		}

		&__actions {
			height: 40px;
		}
	}
}


@keyframes shine-lines {
	0% {
		background-position: -100px;
	}

	40%, 100% {
		background-position: 140px;
	}
}
