.header-actions {
	display: flex;
	gap: 16px;
	transform: translate(0, -4px);

	&__item {
		min-width: 70px;

		button,
		a {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		&-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 0 8px;
			position: relative;
		}

		&-title {
			font-size: 16px;
			line-height: 24px;
			text-align: center;
			color: #FFFFFF;

			opacity: 0.6;
		}

		&-counter {
			position: absolute;
			right: -7px;
			top: -7px;
			background: linear-gradient(270deg, #FF9900 0%, #FF4B55 100%);
			display: flex;
			align-items: center;
			justify-content: center;
			height: 15px;
			box-sizing: border-box;
			padding: 3px;
			font-size: 10px;
			line-height: 11px;
			border-radius: 15px;
			min-width: 23px;
		}
	}
}
