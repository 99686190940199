.mobile-body {
	.header-actions {
		display: flex;
		gap: 12px;
		transform: none;

		&__item {
			min-width: auto;

			button,
			a {
				height: 32px;
				width: 32px;
				opacity: 1;
				align-items: center;
				justify-content: center;
			}

			&-icon {
				margin: 0;

				img {
					width: 22px;
					height: auto;
					display: block;
				}
			}
		}
	}
}
