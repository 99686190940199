.articles {
	&-list {
		display: flex;
		gap: 24px;
		flex-wrap: wrap;
		list-style: none;
		margin: 0;
		padding: 0;

		&-item {
			width: calc(33.33% - 16px);
			min-height: 220px;
			box-sizing: border-box;
			background-size: cover;
			background-repeat: no-repeat;
			border-radius: 8px;

			a {
				display: flex;
				height: 100%;
				width: 100%;
				flex-direction: column;
				justify-content: flex-end;
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 66.99%);
				border-radius: 8px;
				padding: 16px;
				color: #fff;
			}

			&__content {
				display: flex;
				flex-direction: column;

				span {
					display: block;
				}
			}

			&__description {
				font-weight: 600;
				font-size: 16px;
				line-height: 22px;
				margin-bottom: 10px;
			}

			&__subtitle {
				font-weight: 400;
				font-size: 14px;
				line-height: 20px;
			}
		}
	}
}
