.price-range-filter {
	display: flex;
	gap: 8px;

	&__input-wrapper {
		display: flex;
		flex-direction: column;
		padding: 8px 12px;
		gap: 4px;

		width: 126px;
		height: 48px;

		background: rgba(162, 162, 162, 0.1);
		border-radius: 8px;
	}

	&__input-label {
		font-family: 'Inter';
		font-weight: 400;
		font-size: 12px;
		line-height: 12px;
		color: rgba(0, 0, 0, 0.4);
	}

	&__input {
		&,
		&:focus {
			background: none;
			box-shadow: none;
			border: 0;
			outline: none;
		}
		padding: 0;
		width: 100%;
		height: 16px;
		font-family: 'Inter';
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		color: rgba(0, 0, 0, 0.8);
	}
}
