.mobile-body {
	.footer__box {
		border-bottom: 1px solid rgba(255, 255, 255, .33);
		margin: 0 -16px;
		padding: 20px 16px;
		gap: 7px;
		display: flex;
		flex-direction: column;

		&-title {
			font-weight: 600;
			font-size: 16px;
			line-height: 145%;

			color: #FFFFFF;

			margin-bottom: 0;
			display: flex;
			justify-content: space-between;
			align-items: baseline;

			& + .footer__box-item {
				margin-top: 11px;
			}
		}

		&-item {
			opacity: 1;
			color: rgba(255, 255, 255, 0.7);
			margin-bottom: 0;
			font-weight: 400;
			font-size: 14px;
			line-height: 165%;
		}

		&.no-border {
			border-bottom: 0;
		}
	}
}
