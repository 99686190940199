.input-with-label-wrapper {
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px 12px;
	gap: 8px;
	height: 48px;
	background: #FFFFFF;
	border: 1px solid rgba(162, 162, 162, 0.4);
	border-radius: 8px;
	position: relative;

	&_disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	input {
		border: 0;
		box-shadow: none;
		outline: none;
		padding: 4px 0;
		font-weight: 400;
		font-size: 16px;
		line-height: 20px;
		color: #000000;
		width: 100%;
	}

	span {
		position: absolute;
		left: 12px;
		top: 13px;
		font-weight: 400;
		font-size: 16px;
		line-height: 20px;
		color: rgba(0, 0, 0, 0.4);
		transition: all 0.1s linear;
	}

	input:focus + span,
	&_not-empty span {
		top: 6px;
		font-weight: 400;
		font-size: 12px;
		line-height: 12px;
		color: rgba(0, 0, 0, 0.4);
	}

	input:focus,
	&_not-empty input {
		transform: translate(0, 8px);
		font-size: 14px;
		line-height: 16px;
	}
}
